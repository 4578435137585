import React from 'react'
import Layout from '../components/layout'
import { Helmet } from 'react-helmet'

export default function Investment() {
  return (
    <Layout page='investment' bg='https://s3.amazonaws.com/flyingpoint/nycbg2.jpg'>
      <Helmet>
        <title>Investment Strategy | Flying Point</title>
      </Helmet>
      <h1>Investment Strategy</h1>
      <p>
      We apply an entrepreneurial, tenant driven approach built around proprietary data and an extensive network of industry relationships. We leverage our expertise in acquisitions, development, leasing, and management which allows us to swiftly execute on targeted opportunities. Our goal is to mitigate downside risk while providing superior risk adjusted returns for our partners and investors.
      </p>
      <h1>Acquisiton Criteria</h1>
      <ul>
        <li>Deal Size: <b>$1 - 20M</b></li>
        <li>Markets: <b>South Florida and New York Metro Area</b></li>
        <li>Asset Classes: <b>Retail, Industrial & Multifamily</b></li>
        <li>Strategies: <b>Value-Added and Opportunistic</b></li>
      </ul>
    </Layout>
  );
}